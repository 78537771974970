<template>
  <div>
    <Banner />
    <div class="wrapper">
      <PopularGames></PopularGames>
      <Sports></Sports>
      <div id="dp" class="stick date-filter">
        <div class="highlight-box pt-0">
          <section class="bets-menu d-flex justify-content-between">
            <div v-if="fixture_filter === 1" class="w-20 active text-center">
              {{ $t("highlights") }}
            </div>
            <div
              v-else
              class="w-20 text-blue text-center"
              @click="setHighlight"
            >
              <a>{{ $t("highlights") }}</a>
            </div>

            <div v-if="fixture_filter === 2" class="w-20 active text-center">
              {{ $t("boosted") }}
            </div>
            <div v-else class="w-20 text-blue text-center" @click="setBoosted">
              <a> {{ $t("boosted") }}</a>
            </div>

            <div v-if="fixture_filter === 3" class="w-20 active text-center">
              {{ $t("upcoming") }}
            </div>
            <div v-else class="w-20 text-blue text-center" @click="setUpcoming">
              <a>{{ $t("upcoming") }}</a>
            </div>

            <div v-if="fixture_filter === 4" class="w-20 active text-center">
              {{ $t("all") }}
            </div>
            <div v-else class="w-20 text-blue text-center" @click="setAll">
              <a>{{ $t("all") }}</a>
            </div>
          </section>
        </div>
      </div>
      <SportFilters />
      <div v-if="isLeagueFilter">
        <LeagueSelector :groupedByCountry="groupedByCountry" />
        <div class="actions">
          <div>
            <ChopbetButton @click="handleReset" variant="outline">
              {{ $t("reset") }}
            </ChopbetButton>
            <ChopbetButton
              @click="handleApply"
              variant="primary"
              :disabled="checkedTournament.length > 5"
            >
              {{ $t("apply") }}
            </ChopbetButton>
          </div>
        </div>
      </div>
      <div v-else>
        <Games
          v-bind:highlights="highlights"
          v-bind:boosted="boosted"
          v-bind:upcoming="upcoming"
          v-bind:all="all"
          v-bind:jackpot="jackpot"
          v-bind:today="today"
          v-bind:tomorrow="tomorrow"
          v-bind:sport="sport_id"
        >
        </Games>
      </div>
    </div>
  </div>
</template>

<script>
const Sports = () => import("./accordion/sports.vue");
const Games = () => import("./Games.vue");
const PopularGames = () => import("./popularGames/PopularGames.vue");
import fixture from "@/services/fixtures";
import Banner from "../components/banner/Banner.vue";
import jQuery from "jquery";
import SportFilters from "./ui/SportFilters.vue";
import LeagueSelector from "./ui/LeagueSelector.vue";
import ChopbetButton from "./ui/ChopbetButton.vue";

const $ = jQuery;

export default {
  name: "Sport",
  components: {
    PopularGames,
    Sports,
    Games,
    Banner,
    SportFilters,
    LeagueSelector,
    ChopbetButton,
  },
  data: function () {
    return {
      highlights: this.$store.state.fixture_filter === 1,
      boosted: this.$store.state.fixture_filter === 2,
      upcoming: this.$store.state.fixture_filter === 3,
      all: this.$store.state.fixture_filter === 4,
      tomorrow: false,
      today: false,
      sub_page: "",
      mqttClient: "",
      homePagePopularGames: [
        // {
        //   providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SPRIBE),
        //   gameID: "aviator",
        //   gameName: "Aviator",
        //   image: "/img/home/Aviator.jpg",
        //   crashGameUrl: "aviator",
        // },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SPRIBE),
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/home/Aviator.jpg",
          crashGameUrl: "aviator",
        },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_BOOMING),
          gameID: "657c5fe0a8abcd000146d9ab",
          gameName: "Ronaldinho Spins",
          image:
            "https://yard.gcsd.io/assets/games/657c5fe0a8abcd000146d9ab/v1.0.27/logos/en/mobile.png",
          crashGameUrl: "ronaldinho-spins",
        },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SMART),
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
          crashGameUrl: "jetx",
        },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_STP),
          gameID: "1",
          gameName: "Comet",
          image: "/img/home/CometCrash.jpg",
          crashGameUrl: "comet",
        },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_ELBET),
          gameID: "rocketman",
          gameName: "RocketMan",
          image: "/img/home/Rocketman.jpg",
          crashGameUrl: "rocketman",
        },
        // {
        //   providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_STP),
        //   gameID: "5",
        //   gameName: "Monkey Bizniz",
        //   image: "/img/home/Monkey.png",
        //   crashGameUrl: "monkeybizniz",
        // },
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_ELBET),
          gameID: "FootballX",
          gameName: "FootballX",
          image: "/img/home/FootballX.png",
          crashGameUrl: "footballx",
        },
        // {
        //   providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_STP),
        //   gameID: "9",
        //   gameName: "Street Dice",
        //   image: "/img/home/Dice.png",
        //   crashGameUrl: "streetdice",
        // },
      ],
    };
  },

  watch: {
    fixture_filter() {
      this.highlights = this.$store.state.fixture_filter === 1;
      this.boosted = this.$store.state.fixture_filter === 2;
      this.upcoming = this.$store.state.fixture_filter === 3;
      this.all = this.$store.state.fixture_filter === 4;
    },
    isLeagueFilter() {
      // console.log(this.isLeagueFilter, "isLeagueFilter");
      this.isLeagueFilter = this.$store.state.isLeagueFilter;
    },
  },
  mounted() {
    this.$store.dispatch("setSelectedTournament", []);
    this.$store.dispatch("setLeagueFilter", false);

    // console.log(this.isLeagueFilter, "isLeagueFilter");
    //this.$store.dispatch("setSportID",1);
    // this.$store.dispatch("setHour", -1);
    // this.$store.dispatch("setMarketID",1);
    // this.$store.dispatch("setCompetitionID", 0);
    // this.$store.dispatch("setFixture", {});
    // reset game filter
    //this.$store.dispatch("resetAllGames");
    // var vm = this;
    // this.reloadProfile();
    //  if(vm.getProfile().np == 1 && vm.getProfile().b1 == 0 ){
    //     document.getElementById("launch").click();
    //   }
    // this.$store.dispatch("setCurrentPage", "sport");
  },
  computed: {
    fixture_filter: function () {
      return this.$store.state.fixture_filter;
    },
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    isLeagueFilter: function () {
      return this.$store.state.isLeagueFilter;
    },
    checkedTournament: function () {
      return this.$store.state.selectedTournament;
    },
    groupedByCountry: function () {
      return this.$store.state.tournamentGrouped;
    },
  },
  methods: {
    getLaunchURL: function (launch_url) {
      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;
    },
    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },
    generateGameUrl(game) {
      return `/casinogames?IsDemo=0&providerID=${game.provider_id}&gameName=${game.game_name}&gameID=${game.game_id}&launchURL=${game.launch_url}&launchType=${game.launch_type}&launchParameters=${game.launch_parameters}`;
    },
    setHighlight: function () {
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setFixtureFilter", 1);
    },
    setBoosted: function () {
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setFixtureFilter", 2);
    },
    setUpcoming: function () {
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setFixtureFilter", 3);
    },
    setAll: function () {
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setFixtureFilter", 4);
    },
    setJackpot: function () {
      this.$store.dispatch("setLeagueFilter", false);
    },
    setToday: function () {
      this.$store.dispatch("setCurrentTab", "today");
      var path = process.env.VUE_APP_BASE_FIXTURE_URL;
      var sportId = parseInt(this.sport_id) > 0 ? this.sport_id : 1;
      path += `/highlights/${sportId}`;
      fixture
        .get(path, {
          params: {
            page: 1,
            per_page: 20,
            highlight_market_id: 0,
            tournament_id: 0,
            category_id: 0,
            daily: 0,
            hours: 0,
            match_live_status: 0,
            today: 1,
            tomorrow: 0,
            upcoming: 0,
            boosted: 0,
          },
        })
        .then((response) => {
          // Handle the API response
          this.highlightsData = response.data; // Store the API data
          console.log("Fetched Highlights:", response.data);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching highlights:", error);
        });
    },
    setTomorrow: function () {
      this.$store.dispatch("setCurrentTab", "tomorrow");
      var path = process.env.VUE_APP_BASE_FIXTURE_URL;

      var sportId = parseInt(this.sport_id) > 0 ? this.sport_id : 1;
      path += `/highlights/${sportId}`;
      fixture
        .get(path, {
          params: {
            page: 1,
            per_page: 20,
            highlight_market_id: 0,
            tournament_id: 0,
            category_id: 0,
            daily: 0,
            hours: 0,
            match_live_status: 0,
            today: 0,
            tomorrow: 1,
            upcoming: 0,
            boosted: 0,
          },
        })
        .then((response) => {
          // Handle the API response
          this.highlightsData = response.data; // Store the API data
          console.log("Fetched Highlights:", response.data);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching highlights:", error);
        });
    },
    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    isHighlight: function () {
      return this.highlights;
    },
    isUpcoming: function () {
      return this.upcoming;
    },
    isBoosted: function () {
      return this.boosted;
    },
    isAll: function () {
      return this.all;
    },
    isJackpot: function () {
      return this.jackpot;
    },
    isToday: function () {
      return this.today;
    },
    isTomorrow: function () {
      return this.tomorrow;
    },
    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);
    },

    handleReset() {
      const filters = {
        ...this.$store.state.market_filters,
        byLeagues: 0,
        tournament_id: 0,
      };
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setMarketFilters", filters);
    },
    handleApply() {
      const filters = {
        ...this.$store.state.market_filters,
        byLeagues: this.checkedTournament.join(",") || 0,
        tournament_id: this.checkedTournament.join(",") || 0,
      };
      this.$store.dispatch("setLeagueFilter", false);
      this.$store.dispatch("setMarketFilters", filters);
    },
  },

  beforeDestroy: function () {
    this.$store.dispatch("setLeagueFilter", false);
  },
};

window.addEventListener("scroll", () => {
  //var viewportOject = $('#infoY').offset().top - 50;
  var scrollPos = $(document).scrollTop();
  //console.log("vv"+viewportOject);
  //console.log("SS"+scrollPos);
  if (scrollPos >= 150) {
    // $("#dp").addClass("stick");
    //alert("I am here");
  } else {
    // $("#dp").removeClass("stick");
  }
});
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--background-color);
  color: var(--text-color);
}

.bets-menu {
  overflow: scroll;
}

.bets-menu > div {
  padding: 8px 0;
  min-width: 67px;
  font-weight: 500;
  margin: 3px 0;
  cursor: pointer;
}

.bets-menu > div a {
  color: var(--text-color);
}

.bets-menu::-webkit-scrollbar {
  display: none;
}

.game-image {
  width: 90%;
  height: 90%;
  border-radius: 5px;
  object-fit: contain;
}

.game-image-container {
  width: 100%;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);
}

.list-active {
  background-color: #f08b05;
}

.custom-divider {
  border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
}

.mobile-container {
  display: flex;
  flex-wrap: wrap;
}

.mobile-top-left-corner {
  background-color: white;
  border-top: 1.5px solid rgba(209, 201, 201, 0.979);
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-top-left-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-top-right-corner {
  background-color: white;
  border-top: 1.5px solid rgba(209, 201, 201, 0.979);
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-right: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-top-right-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-bottom-left-corner {
  background-color: white;
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom-left-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-bottom-right-corner {
  background-color: white;
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-right: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom-right-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

span {
  font-weight: 700;
}

.date-filter {
  padding: 4px 20px;
  border-top: 1px var(--border) solid;
  border-bottom: 1px var(--border) solid;
}

.bets-menu a {
  color: #8c8787;
  text-decoration: none;
}

.bets-menu a:not([href]),
a:not([href]):hover {
  text-decoration: none;
  color: #8c8787;
}

.actions {
  gap: 4px;
  position: sticky;
  z-index: 20;
  bottom: 0rem;
  left: 0;
  background-color: var(--background-color);
  padding: 0.4rem 15px;
  padding-bottom: 0.5rem;
}
.actions > div {
  display: flex;
}
</style>
